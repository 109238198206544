import React from 'react';
import {useRouter} from '../hooks/use-router';
import {useAuth} from '../hooks/use-auth';
import {ButtonDropdown} from '@amzn/awsui-components-react';
import '../../assets/styles/nav-styles.scss';

export default function TopNavigationBar() {
    const auth = useAuth();
    const router = useRouter();

    const topItems: {label: string; pathname: string}[] = [];

    if (auth.authInformation?.globalPermissions && auth.authInformation?.globalPermissions['canViewOperationalDashboard']) {
        topItems.push({label: 'Dashboard', pathname: '/operational-dashboard'});
    }

    if (auth.authInformation?.current?.permissions.canManageForecastWorkflows) {
        topItems.push({label: 'Orchestrator', pathname: '/operational-schedule'});
    }

    if (auth.authInformation?.current?.permissions.canViewForecasts) {
        topItems.push({label: 'View Forecasts', pathname: '/forecast'});
    }

    if (auth.authInformation?.current?.permissions.canUploadBulkOverrides) {
        topItems.push({label: 'Bulk Overrides', pathname: '/overrides'});
    }

    if (auth.authInformation?.current?.permissions.canUploadMarketingInputs) {
        topItems.push({label: 'Input Signals', pathname: '/signals'});
    }

    if (auth.authInformation?.current?.permissions.canViewForecasts) {
        topItems.push({label: 'Forecast History', pathname: '/history'});
    }

    topItems.push({label: 'Support', pathname: '/support'});

    topItems.push({label: 'Insights', pathname: '/insights'});

    let subtitle = <div></div>;
    let flowSelection = <div></div>;
    if (auth.authInformation?.current) {
        subtitle = (
            <div
                className="navbar-subtitle"
                onClick={(event) => {
                    event.preventDefault();
                    router.push('/business-selection');
                }}
            >
                ({getBusinessIdDisplayNames(auth.authInformation?.current.businessId) + ' ' + auth.authInformation?.current.country}
                )
            </div>
        );

        const flows = (auth.authInformation?.businessContexts || [])
            .filter((v) => v.businessId === auth.authInformation?.current?.businessId)
            .filter((v) => v.country === auth.authInformation?.current?.country)
            .map((v) => v.flow);

        if (flows.length > 1) {
            flowSelection = (
                <div className="navbar-flowDropDown">
                    <ButtonDropdown
                        data-testid="flow-selection"
                        onItemClick={async (item) => {
                            const flow = item.detail.id;
                            await auth.authInformation?.switchTo(
                                auth.authInformation?.current!.businessId,
                                auth.authInformation?.current!.country,
                                flow
                            );

                            // Let's app do the routing
                            router.push('/');
                        }}
                        items={flows.map((v) => ({text: v, id: v}))}
                    >
                        Flow - {auth.authInformation?.current!.flow}
                    </ButtonDropdown>
                </div>
            );
        }
    }

    return (
        <nav className={'website-navigation-top navigation'} id="a">
            <div className={'awsui-grid'} style={{width: '100%'}}>
                <div className={'awsui-row'}>
                    <div className="col-2" style={{padding: 0, minWidth: 250}}>
                        <div className="navbar-title-container">
                            <div className="navbar-title" onClick={() => router.push('/business-selection')}>
                                EXCELSIOR
                            </div>
                            {subtitle}
                        </div>
                    </div>
                    <div className="col-2" style={{padding: 0}}>
                        <div style={{minWidth: 150}}>{flowSelection}</div>
                    </div>
                    <div className="col-6 typeName" style={{padding: 0, display: 'flex', flexDirection: 'row', zIndex: 2}}>
                        {topItems.map((item) => (
                            <div
                                className={
                                    item.pathname === router.pathname
                                        ? 'awsui-util-p-m navbar-link navbar-link-selected'
                                        : 'awsui-util-p-m navbar-link'
                                }
                                key={item.label}
                                onClick={(event) => {
                                    event.preventDefault();
                                    router.push(item.pathname);
                                }}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    );
}

function getBusinessIdDisplayNames(businessId: string): string {
    const businessIdToDisplayNameMap: {[key: string]: string} = {
        wfm: 'wfmoa',
    };

    if (businessIdToDisplayNameMap[businessId]) {
        return businessIdToDisplayNameMap[businessId];
    }
    return businessId;
}
