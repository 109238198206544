import React, {useState} from 'react';
import '../../assets/styles/insights-styles.scss';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {usePrepareInsights, useGetInsights} from '../hooks/use-forecast-store-api';
import {Button, DateRangePicker, Header, Input, Select, SelectProps, SpaceBetween, Flashbar} from '@amzn/awsui-components-react';
import Table, {TableProps} from '@amzn/awsui-components-react/polaris/table';
import {translateErrorToReactNode} from '../common';
import {Notification} from '../navigation/page-layout';
import {InsightList} from '@amzn/f3-excelsior-forecast-store-lambda/clients/f3excelsiorforecaststorelambda';

interface InsightsProps {
    pushNotification: (notification: Notification) => void;
}

export default function Insights(props: InsightsProps) {
    const auth = useAuth();
    const forecastStoreInsightsClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(
        ServiceResource.ForecastStoreInsights
    );

    const [businessId, setBusinessId] = useState(null as SelectProps.Option | null);
    const [country, setCountry] = useState(null as SelectProps.Option | null);
    const [countryOptions, setCountryOptions] = useState([] as SelectProps.Option[]);
    const businessIdOptions = Array.from(new Set((auth.authInformation?.businessContexts || []).map((v) => v.businessId))).map(
        (v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option)
    );
    const [flow, setFlow] = useState(null as SelectProps.Option | null);
    const flowOptions = Array.from(new Set((auth.authInformation?.businessContexts || []).map((v) => v.flow))).map(
        (v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option)
    );
    const [forecastHierarchy, setForecastHierarchy] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState(undefined as any);

    const selectedBusinessId = businessId === null ? '' : businessId.value === undefined ? '' : businessId.value;
    const selectedCountry = country === null ? '' : country.value === undefined ? '' : country.value;
    const selectedFlow = flow === null ? '' : flow.value === undefined ? '' : flow.value;

    const [statusMessage, setStatusMessage] = useState('');
    const [statusInfo, setStatusInfo] = useState('info' as 'success' | 'warning' | 'info' | 'error');

    const [buttonClicked, setButtonClicked] = useState(false);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
            });
        };
    }

    const {execute: executePrepareInsights, value: prepareInsightsResponse} = usePrepareInsights(
        forecastStoreInsightsClientConfiguration,
        createErrorListener('PrepareInsights failed'),
        [auth]
    );

    const {execute: executeGetInsights, value: getInsightsResponse} = useGetInsights(
        forecastStoreInsightsClientConfiguration,
        createErrorListener('GetInsights failed'),
        [auth]
    );

    const prepareAndGetInsightsUntilFinish = async () => {
        const prepareResponse = await executePrepareInsights({
            businessId: selectedBusinessId,
            country: selectedCountry,
            flow: selectedFlow,
            startTime: new Date(dateRangeValue.startDate).toISOString(),
            endTime: new Date(dateRangeValue.endDate).toISOString(),
            forecastHierarchy: forecastHierarchy.split('-'),
        });

        setStatusMessage('Insights Retrieval In Progress... (id = ' + prepareResponse.insightId + ')');
        setStatusInfo('info');
        let pollingAttempt = 0;
        const delay = 1000;
        const maxPollAttempt = 100;

        const interval = setInterval(async () => {
            const getResponse = await executeGetInsights({
                businessId: selectedBusinessId,
                country: selectedCountry,
                flow: selectedFlow,
                insightId: prepareResponse.insightId,
            });
            const getStatus = getResponse.status;
            if (pollingAttempt >= maxPollAttempt || getStatus === 'success' || getStatus === 'error') {
                if (getStatus === 'success') {
                    setStatusMessage('Insights Successfully Retrieved! (id: ' + prepareResponse.insightId + ' )');
                    setStatusInfo('success');
                } else if (getStatus === 'error') {
                    setStatusMessage('Insights Retrieval Failed with Message: ' + getResponse.error);
                    setStatusInfo('error');
                } else {
                    setStatusMessage('Error: Insights Retrieval took too long');
                    setStatusInfo('error');
                }
                clearInterval(interval);
                return;
            }
            pollingAttempt++;
        }, delay);
    };

    const generateTableColumns = (insights: InsightList) => {
        if (insights.length === 0) return [];
        const tableColumns: TableProps.ColumnDefinition<Map<string, string>>[] = [];
        tableColumns.push({
            id: 'forecastHierarchy',
            header: 'Forecast Hierarchy',
            cell: (e) => e.get('forecastHierarchy'),
            width: 280,
            minWidth: 280,
            sortingField: 'forecastHierarchy',
        });
        tableColumns.push({
            id: 'datetime',
            header: 'Date Time',
            cell: (e) => e.get('datetime'),
            width: 240,
            minWidth: 240,
            sortingField: 'datetime',
        });
        const insight = insights[0];
        for (const insightKey in insight) {
            if (insightKey === 'ratioMap') {
                for (const rKey in insight.ratioMap) {
                    const ratioKey = rKey + 'Ratio';
                    const ratioHeader = rKey.charAt(0).toUpperCase() + rKey.slice(1) + ' Ratio';
                    tableColumns.push({
                        id: ratioKey,
                        header: ratioHeader,
                        cell: (e) => e.get(ratioKey),
                        width: 180,
                        minWidth: 175,
                        sortingField: ratioKey,
                    });
                }
            } else if (!(insightKey === 'forecastHierarchy' || insightKey === 'datetime')) {
                const headerString = insightKey.charAt(0).toUpperCase() + insightKey.slice(1);
                tableColumns.push({
                    id: insightKey,
                    header: headerString,
                    cell: (e) => e.get(insightKey),
                    width: 180,
                    minWidth: 175,
                    sortingField: insightKey,
                });
            }
        }
        return tableColumns;
    };
    const tableColumns = generateTableColumns(getInsightsResponse?.insights ? getInsightsResponse?.insights : []);

    const generateTableContent = (insights: InsightList) => {
        const tableContent: Map<string, string>[] = [];

        for (const insight of insights) {
            const insightMap = new Map<string, string>();
            for (const insightKey in insight) {
                if (insightKey === 'ratioMap') {
                    for (const rKey in insight.ratioMap) {
                        const ratioKey = rKey + 'Ratio';
                        insightMap.set(ratioKey, insight[insightKey][rKey].toString());
                    }
                }
                insightMap.set(insightKey, insight[insightKey].toString());
            }
            tableContent.push(insightMap);
        }
        return tableContent;
    };

    const tableContent = generateTableContent(getInsightsResponse?.insights ? getInsightsResponse?.insights : []);

    return (
        <div>
            {buttonClicked && (
                <Flashbar
                    items={[
                        {
                            type: statusInfo,
                            content: statusMessage,
                            dismissible: true,
                            dismissLabel: 'Clear',
                            onDismiss: () => {
                                setStatusMessage('');
                                setStatusInfo('info');
                                setButtonClicked(false);
                            },
                            id: 'message_1',
                        },
                    ]}
                />
            )}
            <SpaceBetween direction="vertical" size="m">
                <div className="selection-content">
                    <SpaceBetween direction="horizontal" size="xxl">
                        <SpaceBetween direction="vertical" size="xxs">
                            <h4>Business</h4>
                            <Select
                                data-testid="business-selection"
                                className={'business-dropdown'}
                                placeholder={'Select Business'}
                                selectedOption={businessId}
                                onChange={({detail}) => {
                                    setBusinessId(detail.selectedOption);

                                    const newCountryOptions = Array.from(
                                        new Set(
                                            (auth.authInformation?.businessContexts || [])
                                                .filter(
                                                    (v) =>
                                                        !detail.selectedOption.value || v.businessId === detail.selectedOption.value
                                                )
                                                .map((v) => v.country)
                                        )
                                    ).map((v) => ({label: v, value: v} as SelectProps.Option));

                                    setCountryOptions(newCountryOptions);
                                    setCountry(null);
                                }}
                                options={businessIdOptions}
                                selectedAriaLabel="Selected"
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <h4>Country</h4>
                            <Select
                                data-testid="country-selection"
                                className={'country-dropdown'}
                                placeholder={'Select Country'}
                                selectedOption={country}
                                onChange={({detail}) => setCountry(detail.selectedOption)}
                                options={countryOptions}
                                selectedAriaLabel="Selected"
                                disabled={!businessId?.value}
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <h4>Flow</h4>
                            <Select
                                data-testid="flow-selection"
                                className={'flow-dropdown'}
                                placeholder={'Select Flow'}
                                selectedOption={flow}
                                onChange={({detail}) => setFlow(detail.selectedOption)}
                                options={flowOptions}
                                selectedAriaLabel="Selected"
                                disabled={!businessId?.value}
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <h4>Forecast Hierarchy</h4>
                            <Input
                                data-testid="forecast-hierarchy-selection"
                                onChange={({detail}) => setForecastHierarchy(detail.value)}
                                value={forecastHierarchy}
                                placeholder="store-logistic-metric"
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <h4>Date Range</h4>
                            <DateRangePicker
                                data-testid="date-selection"
                                onChange={({detail}) => {
                                    setDateRangeValue(detail.value);
                                }}
                                value={dateRangeValue}
                                relativeOptions={[]}
                                isValidRange={() => ({valid: true})}
                                i18nStrings={{
                                    todayAriaLabel: 'Today',
                                    nextMonthAriaLabel: 'Next month',
                                    previousMonthAriaLabel: 'Previous month',
                                    customRelativeRangeDurationLabel: 'Duration',
                                    customRelativeRangeDurationPlaceholder: 'Enter duration',
                                    customRelativeRangeOptionLabel: 'Custom range',
                                    customRelativeRangeOptionDescription: 'Do NOT use range.',
                                    customRelativeRangeUnitLabel: 'Unit of time',
                                    formatRelativeRange: (e) => {
                                        const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                        return `Last ${e.amount} ${t}`;
                                    },
                                    formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                    dateTimeConstraintText: 'Please select valid dates and times in forecast.',
                                    relativeModeTitle: 'Relative range',
                                    absoluteModeTitle: 'Absolute range',
                                    relativeRangeSelectionHeading: 'Choose a range',
                                    startDateLabel: 'Start date',
                                    endDateLabel: 'End date',
                                    startTimeLabel: 'Start time',
                                    endTimeLabel: 'End time',
                                    clearButtonLabel: 'Clear and dismiss',
                                    cancelButtonLabel: 'Cancel',
                                    applyButtonLabel: 'Apply',
                                }}
                                rangeSelectorMode={'absolute-only'}
                                placeholder="Filter by a date and time range"
                                timeOffset={0}
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <h4>Retrieve Insights</h4>
                            <div className="switch-business-button">
                                <Button
                                    data-testid="insights-button"
                                    disabled={
                                        !businessId?.value ||
                                        !country?.value ||
                                        !flow?.value ||
                                        forecastHierarchy.length === 0 ||
                                        dateRangeValue === undefined
                                    }
                                    onClick={async () => {
                                        setButtonClicked(true);
                                        await prepareAndGetInsightsUntilFinish();
                                    }}
                                >
                                    Retrieve Insights
                                </Button>
                            </div>
                        </SpaceBetween>
                    </SpaceBetween>
                </div>
                <div>
                    {getInsightsResponse?.status === 'success' && (
                        <Table
                            data-testid="insights-table"
                            columnDefinitions={tableColumns}
                            items={tableContent}
                            loadingText="Loading resources"
                            resizableColumns
                            sortingDescending
                            header={<Header>{forecastHierarchy} Insights</Header>}
                        />
                    )}
                </div>
            </SpaceBetween>
        </div>
    );
}

function getBusinessIdDisplayNames(businessId: string): string {
    const businessIdToDisplayNameMap: {[key: string]: string} = {
        wfm: 'WFMOA',
        wfminstore: 'WFM INSTORE',
    };

    if (businessIdToDisplayNameMap[businessId]) {
        return businessIdToDisplayNameMap[businessId].toUpperCase();
    }
    return businessId.toUpperCase();
}
